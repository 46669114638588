@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@300;800&display=swap');


@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  min-height: 100vh;
  background: linear-gradient(to bottom right, black, #1a202c, #2a4365);
  background-color: #0d1b2a; 
  background-repeat: no-repeat;
  background-size: cover;
  font-family: 'Raleway', sans-serif;
}  


/* .app-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  padding-top: 5%; 
}

.main-content {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90%;
  height: 60%;
  margin-top: 1rem;
  flex-grow: 0; 
}

.content-box {
  width: 78%;
  height: 98%;
  background-color: #0d1b2a;
  border: 2px solid #39baff;
  border-radius: 50px;
  box-shadow: 0px 0px 20px 5px #39baff;
  position: relative;
  background-size: cover; 
  background-position: center; 
  background-repeat: no-repeat; 
} */


/* @media (max-width: 768px) {
  .main-content {
    width: 95%; 
    height: 70%;
  }
  
  .content-box {
    border-radius: 30px; 
  }
}

@media (max-width: 480px) {
  .main-content {
    width: 100%; 
    height: 80%;
  }
  
  .content-box {
    border-radius: 20px; 
  }
} */



/* Custom animation for the moving background */
@keyframes moveBg {
  0% {
    background-position: 0% 0%;
  }
  100% {
    background-position: 100% 0%;
  }
}


.space-bg {
  animation: moveBg 60s linear infinite; /* Only add animation, no background image */
  overflow: visible; 
}




